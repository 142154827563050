import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  calculatePost,
  fetchSavedJobs,
  fetchVacancies,
  toggleSaveJob,
} from "../utils.js";
import { Link, useNavigate } from "react-router-dom";
import { server } from "../server.js";
import { TypeAnimation } from "react-type-animation";
import { Helmet } from "react-helmet";
import BannerAd from "../ad/BannerAd.js";
import FeedAd from "../ad/FeedAd.js";
import ArticleAd from "../ad/ArticleAd.js";

export const Category = [
  {
    name: "Latest Jobs",
    categoryId: "5ydb3lom",
    image: "/assets/images/home/latest_job.png",
  },
  {
    name: "Results",
    categoryId: "jbwm9i43",
    image: "/assets/images/home/result.png",
  },
  {
    name: "Admission",
    categoryId: "wxq7rtw0",
    image: "/assets/images/home/admission.png",
  },
  {
    name: "Admit Card",
    categoryId: "Oj38G4ef",
    image: "/assets/images/home/admit_card.png",
  },
  {
    name: "Answer Key",
    categoryId: "56dfOp59",
    image: "/assets/images/home/answer_key.png",
  },
  {
    name: "Diploma / ITI",
    categoryId: "c5qou2du",
    image: "/assets/images/home/diploma.png",
  },
  {
    name: "All Board",
    categoryId: "pif9hewx",
    image: "/assets/images/home/allboard.png",
  },
];

export const States = [
  {
    name: "Uttar Pradesh",
    categoryId: "npb0bq1l",
    image: "/assets/images/states/uttar_pradesh.png",
  },
  {
    name: "Rajasthan",
    categoryId: "z1q3fjke",
    image: "/assets/images/states/rajasthan.png",
  },
  {
    name: "Bihar",
    categoryId: "g6i0qsld",
    image: "/assets/images/states/bihar.png",
  },
  {
    name: "Haryana",
    categoryId: "x5glmuop",
    image: "/assets/images/states/haryana.png",
  },
  {
    name: "Chhattisgarh",
    categoryId: "g18hc4mg",
    image: "/assets/images/states/chhattisgarh.png",
  },
  {
    name: "Gujarat",
    categoryId: "jpxapkfz",
    image: "/assets/images/states/gujarat.png",
  },
  {
    name: "West Bengal",
    categoryId: "ud2835w7",
    image: "/assets/images/states/west_bengal.png",
  },
  {
    name: "Andhra Pradesh",
    categoryId: "i3gjw1vw",
    image: "/assets/images/states/andhra_pradesh.png",
  },
  {
    name: "Maharashtra",
    categoryId: "6m5n9ioe",
    image: "/assets/images/states/maharashtra.png",
  },
  {
    name: "Odisha",
    categoryId: "3tbno0a2",
    image: "/assets/images/states/odisha.png",
  },
  {
    name: "Uttarakhand",
    categoryId: "n27mk067",
    image: "/assets/images/states/uttarakhand.png",
  },
  {
    name: "Arunachal Pradesh",
    categoryId: "1b67D1T9",
    image: "/assets/images/states/arunachal_pradesh.png",
  },
  {
    name: "Madhya Pradesh",
    categoryId: "fc18oq3o",
    image: "/assets/images/states/madhya_pradesh.png",
  },
  {
    name: "Delhi",
    categoryId: "adwf4cwi",
    image: "/assets/images/states/delhi.png",
  },
  {
    name: "Assam",
    categoryId: "1Uf7em8T",
    image: "/assets/images/states/assam.png",
  },
  {
    name: "Jharkhand",
    categoryId: "nd428ngn",
    image: "/assets/images/states/jharkhand.png",
  },
  {
    name: "Karnataka",
    categoryId: "g4oBLlc7",
    image: "/assets/images/states/karnataka.png",
  },
  {
    name: "Kerala",
    categoryId: "7hBOytAt",
    image: "/assets/images/states/kerala.png",
  },
  {
    name: "Punjab",
    categoryId: "ykt2jqts",
    image: "/assets/images/states/punjab.png",
  },
  {
    name: "Tamil Nadu",
    categoryId: "vlub9vxt",
    image: "/assets/images/states/tamil_nadu.png",
  },
];

export const Departments = [
  {
    name: "Air Force",
    categoryId: "4m0mpd0y",
    image: "/assets/images/departments/air_force.png",
  },
  {
    name: "Medical",
    categoryId: "twx7us47",
    image: "/assets/images/departments/medical.png",
  },
  {
    name: "Coast Guard",
    categoryId: "s0lzv2h7",
    image: "/assets/images/departments/coast_guard.png",
  },
  {
    name: "Agriculture",
    categoryId: "2lyr0n7k",
    image: "/assets/images/departments/agriculture.png",
  },
  {
    name: "Police",
    categoryId: "t1u9o16c",
    image: "/assets/images/departments/police.png",
  },
  {
    name: "Banking",
    categoryId: "o90g88ug",
    image: "/assets/images/departments/banking.png",
  },
  {
    name: "NTA",
    categoryId: "qfzkl930",
    image: "/assets/images/departments/nta.png",
  },
  {
    name: "SSC",
    categoryId: "k6qkcy1j",
    image: "/assets/images/departments/ssc.png",
  },
  {
    name: "Navy",
    categoryId: "hq8kq0zs",
    image: "/assets/images/departments/navy.png",
  },
  {
    name: "Teachers",
    categoryId: "mewod5t6",
    image: "/assets/images/departments/teachers.png",
  },
  {
    name: "BSF",
    categoryId: "3718x0by",
    image: "/assets/images/departments/bsf.png",
  },
  {
    name: "DRDO",
    categoryId: "rdnkfflb",
    image: "/assets/images/departments/drdo.png",
  },
  {
    name: "Engineer",
    categoryId: "k3s3orr1",
    image: "/assets/images/departments/engineer.png",
  },
  {
    name: "FCI",
    categoryId: "ba9f9911",
    image: "/assets/images/departments/fci.png",
  },
  {
    name: "ISRO",
    categoryId: "2je9lnad",
    image: "/assets/images/departments/isro.png",
  },
  {
    name: "Judge",
    categoryId: "uiifenza",
    image: "/assets/images/departments/judge.png",
  },
  {
    name: "UPSC",
    categoryId: "7ze1aa3w",
    image: "/assets/images/departments/upsc.png",
  },
  {
    name: "NTPC",
    categoryId: "a87u72w7",
    image: "/assets/images/departments/ntpc.png",
  },
  {
    name: "Professor",
    categoryId: "z1ncfjvz",
    image: "/assets/images/departments/professor.png",
  },
  {
    name: "Army",
    categoryId: "vuhwmsum",
    image: "/assets/images/departments/army.png",
  },
];

function Home() {
  const API = `${server}/data.php`;
  const [fetchLatestJobs, setfetchLatestJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stateVacancies, setStateVacancies] = useState({});
  const [departmentVacancies, setDepartmentVacancies] = useState({});
  const [categoryVacancies, setCategoryVacancies] = useState({});

  useEffect(() => {
    const fetchAllVacancies = async () => {
      const counts = {};
      for (const post of States) {
        const count = await fetchVacancies(post.categoryId);
        counts[post.categoryId] = count;
      }
      setStateVacancies(counts);
    };

    fetchAllVacancies();
  }, []);

  useEffect(() => {
    const fetchAllVacancies = async () => {
      const counts = {};
      for (const post of Departments) {
        const count = await fetchVacancies(post.categoryId);
        counts[post.categoryId] = count;
      }
      setDepartmentVacancies(counts);
    };

    fetchAllVacancies();
  }, []);

  useEffect(() => {
    const fetchAllVacancies = async () => {
      const counts = {};
      for (const post of Category) {
        const count = await fetchVacancies(post.categoryId);
        counts[post.categoryId] = count;
      }
      setCategoryVacancies(counts);
    };

    fetchAllVacancies();
  }, []);

  useEffect(() => {
    const fetchLatestJobs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API}?ev_data_id=5ydb3lom`);
        setfetchLatestJobs(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchLatestJobs();
  }, []);

  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    fetchSavedJobs(setSavedJobs);
  }, []);

  const handleToggleSaveJob = (ev_id) => {
    toggleSaveJob(ev_id, setSavedJobs);
  };

  const isJobSaved = (ev_id) => {
    return savedJobs.includes(ev_id);
  };

  const [input, setInput] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      navigate("/search", { state: { searchQuery: input } });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sarkari Result, Sarkari Naukri - Government Jobs</title>
      </Helmet>

      <main>
        <section className="py-3 glow-bg">
          <div className="container py-6">
            <div className="row align-items-center gy-4 justify-content-center">
              <div className="col-xxl-8 col-xl-6 col-md-10">
                <div className="d-flex flex-column gap-5 text-center">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">
                      SARKARI RESULT, SARKARI NAUKRI - GOVERNMENT JOBS
                    </span>
                    <h1 class="mb-0 display-3 fw-semibold">
                      <span class="text-primary ms-2">
                        <TypeAnimation
                          sequence={[
                            "Latest Job",
                            1000,
                            "Result",
                            1000,
                            "Admit Card",
                            1000,
                            "Answer Key",
                            1000,
                          ]}
                          wrapper="span"
                          speed={60}
                          repeat={Infinity}
                        />
                      </span>
                      <span class="typed-cursor" aria-hidden="true"></span>
                      <div className="fs-2 custom-fontFamily fw-normal">
                        Find government jobs
                      </div>
                    </h1>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <form onSubmit={handleSubmit}>
                      <div className="input-group position-relative mb-3 d-flex align-items-center justify-content-end">
                        <input
                          type="text"
                          className="form-control ps-5 ev-search-bar rounded-pill form-control-lg"
                          placeholder="Search Jobs, Results"
                          value={input}
                          onChange={handleChange}
                        />
                        <button
                          className="btn bg-primary text-white position-absolute m-3 z-5 rounded-pill px-5 fw-medium fs-4"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </form>
                    <div className="gap-2 d-flex flex-wrap justify-content-center">
                      {Category.map((post) => {
                        const { ev_id, categoryId, name } = post;
                        const vacancyCount =
                          categoryVacancies[categoryId] ?? "...";
                        return (
                          <Link
                            to="/category"
                            state={{
                              categoryName: name,
                              categoryId: categoryId,
                            }}
                            key={ev_id}
                            className="btn btn-tag fs-5 fw-normal text-dark opacity-75 px-4 btn-sm"
                          >
                            {name}{" "}
                            <span className="text-primary ms-2 fw-semibold">
                              {vacancyCount}+
                            </span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                <div className="mt-2">
                  <img
                    src="/assets/images/banner.png"
                    className="rounded-4 w-100 d-lg-block d-md-block d-none"
                    alt="Sarkari Result Banner"
                  />
                  <img
                    src="/assets/images/banner-m.png"
                    className="rounded-4 w-100 d-lg-none d-md-none d-block"
                    alt="Sarkari Result Banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="py-lg-3 mt-5 pb-5">
          <div class="container pb-lg-4">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div class="row g-3">
                {Departments.slice(0, 5).map((post) => {
                  const { ev_id, name, categoryId, image } = post;
                  return (
                    <div className="col-xxl-3 col-md-4 col-6" key={ev_id}>
                      <Link
                        to="/category"
                        state={{
                          categoryName: name,
                          categoryId: categoryId,
                        }}
                      >
                        <div class="card-home-tabs p-4 rounded-4 py-4">
                          <div class="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                              <img src={image} class="avatar-xs" alt={name} />
                              <span className="fs-5 ms-3 text-dark">
                                {name}
                              </span>
                            </div>
                            <span class="text-reset ms-4 d-lg-block d-none bookmark">
                              <i class="bi bi-chevron-right text-dark fs-4"></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
                {States.slice(1, 4).map((post) => {
                  const { ev_id, name, categoryId, image } = post;
                  return (
                    <div className="col-xxl-3 col-md-4 col-6" key={ev_id}>
                      <Link
                        to="/category"
                        state={{
                          categoryName: name,
                          categoryId: categoryId,
                        }}
                      >
                        <div class="card-home-tabs p-4 rounded-4 py-4">
                          <div class="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                              <img src={image} class="avatar-xs" alt={name} />
                              <span className="fs-5 ms-3 text-dark">
                                {name}
                              </span>
                            </div>
                            <span class="text-reset ms-4 d-lg-block d-none bookmark">
                              <i class="bi bi-chevron-right text-dark fs-4"></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section class="py-5">
          <div class="container">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <BannerAd />
              </div>
            </div>
          </div>
        </section>

        <section class="pb-5">
          <div class="container pb-lg-6">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="row text-center">
                <div className="col-md-12 px-lg-8 mb-8 mt-6">
                  <span className="text-uppercase text-primary fw-semibold ls-md">
                    Browse Department
                  </span>
                  <h2 class="mb-0 h1">
                    Discover jobs across popular Department
                  </h2>
                  <p className="mb-0 fs-4">
                    Explore job opportunities in top departments
                  </p>
                </div>
              </div>
              <div class="row g-3">
                {Departments.slice(6, 9).map((post) => {
                  const { ev_id, name, categoryId, image } = post;
                  const vacancyCount = departmentVacancies[categoryId] ?? "...";
                  return (
                    <div class="col-xxl-3 col-md-4 col-6" key={ev_id}>
                      <Link
                        to="/category"
                        state={{
                          categoryName: name,
                          categoryId: categoryId,
                        }}
                        class="card card-border-primary card-home-tabs rounded-4"
                      >
                        <div class="card-body d-flex flex-column gap-4 text-center">
                          <div>
                            <div class="icon-shape icon-xl bg-light-info rounded-circle">
                              <img src={image} width="25" alt={name} />
                            </div>
                          </div>
                          <div>
                            <h4 class="mb-0">{name}</h4>
                            <span>{vacancyCount}+ Vacancies</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}

                <div class="col-xxl-3 col-md-4 col-6">
                  <Link
                    to="/vacancies"
                    class="card card-border-primary card-home-tabs rounded-4"
                  >
                    <div class="card-body d-flex flex-column gap-4 text-center">
                      <div>
                        <div class="icon-shape icon-xl bg-light-primary rounded-circle">
                          <i className="bi bi-arrows-fullscreen text-primary h3 m-0"></i>
                        </div>
                      </div>
                      <div>
                        <h4 class="mb-0">Explore All</h4>
                        <span>Departments</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                <Link to="/saved" className="mt-2">
                  <img
                    src="/assets/images/saved.png"
                    className="rounded-4 w-100 d-lg-block d-md-block d-none"
                    alt="Sarkari Result Save Job"
                  />
                  <img
                    src="/assets/images/saved-m.png"
                    className="rounded-4 w-100 d-lg-none d-md-none d-block"
                    alt="Sarkari Result Save Job"
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-8 mt-3">
          <div className="container mb-3">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                <div class="text-center mb-8">
                  <span class="text-uppercase text-primary fw-semibold ls-md">
                    Latest Jobs
                  </span>
                  <h2 class="mb-0 h1">Latest job listings</h2>
                  <p className="mb-0 fs-4">
                    Discover the newest job openings and save it
                  </p>
                </div>
                <div className="row g-3">
                  {fetchLatestJobs.slice(0, 3).map((post, index) => {
                    const {
                      ev_id,
                      name,
                      tag,
                      title,
                      postupdatedate,
                      vacancy_details,
                    } = post;
                    return (
                      <div className="col-lg-4 col-md-6 col-12" key={ev_id}>
                        <Link
                          to="/post"
                          state={{ postId: ev_id }}
                          className="card card-bordered card-hover rounded-4 cursor-pointer h-100 p-2"
                        >
                          <div className="card-body text-center">
                            <div className="d-flex justify-content-center mb-3">
                              <span className="badge bg-danger-soft px-4">
                                {tag}
                              </span>
                            </div>
                            <div className="mb-4">
                              <div>
                                <h3 className="mb-1 fs-4">
                                  <span className="text-inherit text-truncate d-block me-2">
                                    {name}
                                  </span>
                                </h3>
                                <div>
                                  <span>
                                    <i className="bi bi-graph-up me-2"></i>
                                  </span>
                                  <span className="ms-0">
                                    {calculatePost(vacancy_details)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="mb-4">
                                <div className="mb-2 mb-md-0">
                                  <div className="mt-1">
                                    <span>{title}</span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <i className="bi bi-calendar4-event me-2"></i>
                                <span>
                                  {moment(postupdatedate).format("DD/MM/YYYY")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}

                  <div class="mt-6 text-center">
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Latest Jobs",
                        categoryId: "5ydb3lom",
                      }}
                      class="btn btn-outline-primary px-5"
                    >
                      Browse All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="py-5">
          <div class="container">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <FeedAd />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container mb-2">
            <div class="row">
              <div class="col-xl-10 offset-xl-1 col-md-12 col-12">
                <div class="p-5 rounded-4 ev-card-border">
                  <div class="row align-items-center mx-lg-2">
                    <div class="col-xl-8 col-md-6 col-12">
                      <div className="d-flex align-items-center">
                        <div className="d-lg-block d-none">
                          <div className="p-2 bg-white rounded-4">
                            <img
                              src="/assets/images/mobile.png"
                              width="130"
                              alt="Download Sarkari Result App"
                            />
                          </div>
                        </div>
                        <div className="ms-lg-4 ms-0 d-flex justify-content-center flex-column">
                          <h2 class="fs-3 mb-2 lh-1">
                            Explore jobs with popular departments
                          </h2>
                          <p class="text-dark-50 fs-5 ev-lh-normal mb-0">
                            Explore a range of exciting job opportunities.
                          </p>
                          <div className="mt-3">
                            <Link
                              to="/category"
                              state={{
                                categoryName: "Coast Guard",
                                categoryId: "s0lzv2h7",
                              }}
                              className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2"
                            >
                              Coast Guard
                              <i className="bi bi-chevron-right ms-3"></i>
                            </Link>
                            <Link
                              to="/category"
                              state={{
                                categoryName: "Police",
                                categoryId: "t1u9o16c",
                              }}
                              className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2"
                            >
                              Police
                              <i className="bi bi-chevron-right ms-3"></i>
                            </Link>
                            <Link
                              to="/category"
                              state={{
                                categoryName: "Banking",
                                categoryId: "o90g88ug",
                              }}
                              className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2"
                            >
                              Banking
                              <i className="bi bi-chevron-right ms-3"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-flex justify-content-end">
                      <div className="d-flex flex-column text-center">
                        <Link
                          to="/vacancies"
                          class="btn btn-primary fw-normal ms-1 rounded-pill px-5"
                        >
                          Explore All
                        </Link>
                        <p className="mt-1 fs-6 mb-0">20+ departments</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="pb-lg-8 mb-3">
          <div class="container">
            <div class="row">
              <div class="col-xl-10 offset-xl-1 col-md-12 col-12">
                <div class="bg-blue-gradient px-5 pt-5 rounded-4">
                  <div class="row align-items-end mx-lg-2">
                    <div class="col-xl-6 col-md-6 col-12">
                      <div>
                        <div className="d-flex justify-content-start">
                          <div className="p-2 bg-white mb-4 rounded-4">
                            <img
                              src="/assets/images/qr.png"
                              width="100"
                              alt="Download Sarkari Result App QR"
                            />
                          </div>
                        </div>
                        <h2 class="h1 lh-1">Download Our App</h2>
                        <p class="text-dark-50 fs-4 mb-4">
                          Get real-time latest job, result updates & more!
                        </p>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                          target="_blank"
                        >
                          <img
                            src="/assets/images/google-play.svg"
                            alt="Google Play"
                            class="img-fluid mb-5 pb-5"
                            width="150"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12">
                      <div class="text-center d-none d-md-block">
                        <img
                          src="/assets/images/jobs.png"
                          alt="Government Jobs, Sarkari Result"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mb-5 mt-lg-0 mt-5 pt-lg-0 pt-5 pb-3">
            <div className="row">
              <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
                <div className="row text-center">
                  <div className="col-md-12 px-lg-8 mb-8">
                    <span className="text-uppercase text-primary fw-semibold ls-md">
                      Browse States
                    </span>
                    <h2 class="mb-0 h1">Discover jobs across States</h2>
                    <p className="mb-0 fs-4">
                      Explore job opportunities across various states
                    </p>
                  </div>
                </div>
                <div className="row g-3">
                  {States.slice(0, 7).map((post) => {
                    const { ev_id, name, categoryId, image } = post;
                    const vacancyCount = stateVacancies[categoryId] ?? "...";
                    return (
                      <div className="col-lg-3 col-md-4 col-6" key={ev_id}>
                        <Link
                          to="/category"
                          state={{
                            categoryName: name,
                            categoryId: categoryId,
                          }}
                          className="card border-top border-4 card-hover-with-icon"
                        >
                          <div className="card-body">
                            <div className="icon-shape icon-lg rounded-circle bg-light mb-3 card-icon">
                              <img src={image} width="35" alt={name} />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <h4 className="mb-0">{name}</h4>
                                <p className="mb-0">
                                  {vacancyCount}+ Vacancies
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                  <div className="col-lg-3 col-md-4 col-6">
                    <Link
                      to="/vacancies"
                      className="card border-top border-4 card-hover-with-icon"
                    >
                      <div className="card-body">
                        <div className="icon-shape icon-lg rounded-circle bg-light mb-3 card-icon">
                          <i className="bi bi-arrows-fullscreen text-primary h3 m-0"></i>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h4 className="mb-0">Explore All</h4>
                            <p className="mb-0">States</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="py-6 mb-3">
          <div class="container mb-5">
            <div className="row text-center">
              <div className="col-md-12 px-lg-8 mb-8">
                <span className="text-uppercase text-primary fw-semibold ls-md">
                  Need to Know
                </span>
                <h2 class="mb-0 h1">Frequently Asked Questions</h2>
                <p className="mb-0 fs-4">
                  Find quick answers to common queries
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8 col-md-8 col-12">
                <div class="accordion accordion-flush" id="accordionExample">
                  <div class="border-bottom py-3" id="headingOne">
                    <h3 class="mb-0 fw-bold">
                      <a
                        href="#"
                        class="d-flex align-items-center text-inherit"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <span class="me-auto">
                          How can I find job listings?
                        </span>
                        <span class="collapse-toggle ms-4">
                          <i class="bi bi-plus text-primary"></i>
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="py-3 fs-4">
                      Sarkari Naukri refers to government job vacancies across
                      various departments and sectors. You can find job listings
                      on our website, which provides updated information on
                      available positions, application deadlines, and
                      eligibility criteria. Additionally, our app offers instant
                      alerts and notifications for new job postings.
                    </div>
                  </div>
                  <div class="border-bottom py-3" id="headingTwo">
                    <h3 class="mb-0 fw-bold">
                      <a
                        href="#"
                        class="d-flex align-items-center text-inherit active"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <span class="me-auto">
                          How to Use Advanced Search option?
                        </span>
                        <span class="collapse-toggle ms-4">
                          <i class="bi bi-plus text-primary"></i>
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="py-3 fs-4">
                      Our advanced search feature allows you to filter job
                      listings based on criteria such as job title, location,
                      department, and eligibility. Simply enter your preferred
                      parameters in the search fields to quickly find vacancies
                      that match your qualifications and interests.
                    </div>
                  </div>

                  <div class="border-bottom py-3" id="headingThree">
                    <h3 class="mb-0 fw-bold">
                      <a
                        href="#"
                        class="d-flex align-items-center text-inherit active"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <span class="me-auto">
                          How do I use the saved jobs facility?
                        </span>
                        <span class="collapse-toggle ms-4">
                          <i class="bi bi-plus text-primary"></i>
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="py-3 fs-4">
                      To keep track of job opportunities, use the saved jobs
                      feature on our website or app. Simply click the 'Save'
                      button next to any job listing you're interested in, and
                      it will be stored in your account. You can access your
                      saved jobs anytime to review or apply later.
                    </div>
                  </div>
                  <div class="pt-3" id="headingFour">
                    <h3 class="mb-0 fw-bold">
                      <a
                        href="#"
                        class="d-flex align-items-center text-inherit active"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <span class="me-auto">
                          Can I view Jobs by state or department?
                        </span>
                        <span class="collapse-toggle ms-4">
                          <i class="bi bi-plus text-primary"></i>
                        </span>
                      </a>
                    </h3>
                  </div>
                  <div
                    id="collapseFour"
                    class="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="py-3 fs-4">
                      Yes, you can view job vacancies categorized by state or
                      department on our platform. Use the state-wise and
                      department-wise search options to easily find
                      opportunities relevant to your location or desired field.
                    </div>
                  </div>
                </div>
                <div class="mt-8 text-center">
                  <Link to="/contact" class="btn btn-outline-primary px-4">
                    More questions? Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="py-5">
          <div class="container">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="d-flex justify-content-center">
                <ArticleAd />
              </div>
            </div>
          </div>
        </section>

        <section class="py-lg-5">
          <div class="container pb-8">
            <div class="row pb-5">
              <div class="col-xl-10 offset-xl-1 col-md-12 col-12">
                <div class="bg-orange-gradient px-lg-5 rounded-4">
                  <div class="row align-items-end mx-lg-2">
                    <div class="col-xl-6 col-md-6 col-12">
                      <div className="px-lg-0 px-5">
                        <div className="d-flex justify-content-start">
                          <div className="my-4">
                            <img
                              src="/assets/images/search.png"
                              alt="Search Department Wise Vacancy"
                              width="170"
                            />
                          </div>
                        </div>
                        <h2 class="h1 lh-1 me-lg-5">
                          Explore Department Wise Job Opportunities
                        </h2>
                        <p class="text-dark-50 fs-4 mb-5 pb-5">
                          Find career opportunities across departments
                        </p>
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12">
                      <div className="bg-white card-bordered card-overflow-top-bottom p-4 d-flex flex-column justify-content-center">
                        <div className="row g-2">
                          {Departments.slice(10, 16).map((post) => {
                            const { ev_id, name, categoryId, image } = post;
                            const vacancyCount =
                              departmentVacancies[categoryId] ?? "...";
                            return (
                              <div className="col-6" key={ev_id}>
                                <Link
                                  to="/category"
                                  state={{
                                    categoryName: name,
                                    categoryId: categoryId,
                                  }}
                                >
                                  <div class="card card-bordered card-hover card-hover-scale p-4 rounded-4">
                                    <div className="d-flex flex-column justify-content-between ev-lh-normal">
                                      <div className="icon-shape icon-md rounded-circle bg-light mb-2 card-icon">
                                        <img
                                          src={image}
                                          width="25"
                                          alt={name}
                                        />
                                      </div>
                                      <span className="fs-5 text-dark fw-medium white-space-nowrap">
                                        {name}
                                      </span>
                                      <span className="fs-6">
                                        {vacancyCount}+ Vacancies
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
