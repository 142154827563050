import axios from "axios";
import { useEffect, useState } from "react";
import Card from "./Card.js";
import { server } from "../server.js";
import { Departments, States } from "./Home.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Saved() {
  const [savedJobs, setSavedJobs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSavedJobs = async () => {
      try {
        const savedJobsData = localStorage.getItem("savedJobs");
        if (savedJobsData) {
          const savedJobsArray = JSON.parse(savedJobsData);
          await fetchSavedJobsDetails(savedJobsArray);
        } else {
          setFilteredData([]);
        }
      } catch (error) {
        console.error("Error fetching saved jobs:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedJobs();
  }, []);

  const fetchSavedJobsDetails = async (savedJobsArray) => {
    try {
      const savedJobsDetailsPromises = savedJobsArray.map((ev_id) => {
        const API = `${server}/details.php`;
        return axios.get(`${API}?ev_details_id=${ev_id}`);
      });
      const savedJobsDetailsResponses = await Promise.all(
        savedJobsDetailsPromises
      );
      const savedJobsDetailsData = savedJobsDetailsResponses.flatMap(
        (response) => response.data
      );
      setSavedJobs(savedJobsDetailsData);
      setFilteredData(savedJobsDetailsData);
    } catch (error) {
      console.error("Error fetching saved job details:", error);
      setError(error);
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredData(savedJobs);
    } else {
      const filtered = savedJobs.filter(
        (post) =>
          post.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          post.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, savedJobs]);

  const totalData = filteredData.length;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>Saved Jobs : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="pt-xl-5 pt-6 glow-bg bg-white">
        <div className="container">
          <div className="row my-4">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">
                      SARKARI RESULT, SARKARI NAUKRI - GOVERNMENT JOBS
                    </span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Explore the Newest Job Openings
                    <br />
                    <span className="text-primary">Saved Jobs</span>
                  </h1>
                  <form
                    className="form-inline px-xl-8 mx-xl-8 mt-3"
                    onSubmit={handleSubmit}
                  >
                    <div className="d-flex align-items-center">
                      <span className="position-absolute ps-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </span>
                      <input
                        type="search"
                        className="form-control ps-6 form-control-lg"
                        placeholder="Filter Saved Jobs"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container mb-4">
          <div class="row">
            <div class="col-xl-10 offset-xl-1 col-md-12 col-12">
              <div class="p-5 rounded-4 ev-card-border bg-white">
                <div class="row align-items-center mx-lg-2">
                  <div class="col-xl-8 col-md-6 col-12">
                    <div className="d-flex align-items-center">
                      <div className="d-lg-block d-none">
                        <div className="p-2 bg-white rounded-4">
                          <img src="/assets/images/mobile.png" width="130" />
                        </div>
                      </div>
                      <div className="ms-lg-4 ms-0 d-flex justify-content-center flex-column">
                        <h2 class="fs-3 mb-2 lh-1">
                          Explore jobs with popular departments
                        </h2>
                        <p class="text-dark-50 fs-5 ev-lh-normal mb-0">
                          Explore a range of exciting job opportunities.
                        </p>
                        <div className="mt-3">
                          <Link
                            to="/category"
                            state={{
                              categoryName: "Coast Guard",
                              categoryId: "s0lzv2h7",
                            }}
                            className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2"
                          >
                            Coast Guard
                            <i className="bi bi-chevron-right ms-3"></i>
                          </Link>
                          <Link
                            to="/category"
                            state={{
                              categoryName: "Police",
                              categoryId: "t1u9o16c",
                            }}
                            className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2"
                          >
                            Police
                            <i className="bi bi-chevron-right ms-3"></i>
                          </Link>
                          <Link
                            to="/category"
                            state={{
                              categoryName: "Banking",
                              categoryId: "o90g88ug",
                            }}
                            className="btn btn-tag fs-6 fw-normal text-dark opacity-75 px-3 btn-sm me-2"
                          >
                            Banking
                            <i className="bi bi-chevron-right ms-3"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6 col-12 d-flex justify-content-end">
                    <div className="d-flex flex-column text-center">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                        target="_blank"
                        class="btn btn-primary fw-normal ms-1 rounded-pill px-5 mt-lg-0 mt-4"
                      >
                        Download App
                      </a>
                      <p className="mt-1 fs-6 mb-0">Explore More</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-lg-8 pb-8 bg-white">
        <div className="container mb-4">
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="row align-items-center mb-5">
                <div className="col">
                  <p className="mb-0">Showing {totalData} Saved Jobs</p>
                </div>
              </div>
              <div className="row g-3">
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading data: {error.message}</p>
                ) : filteredData.length === 0 ? (
                  <p>No saved jobs found.</p>
                ) : (
                  filteredData.map((post) => (
                    <Card
                      key={post.ev_id}
                      ev_id={post.ev_id}
                      name={post.name}
                      tag={post.tag}
                      vacancy_details={post.vacancy_details}
                      title={post.title}
                      postupdatedate={post.postupdatedate}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Saved;
