import React from "react";

const ArticleAd = () => (
  <div>
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-5680003571314768"
      data-ad-slot="8250477188"
    ></ins>
    <script>
      {`
        (adsbygoogle = window.adsbygoogle || []).push({});
      `}
    </script>
  </div>
);

export default ArticleAd;
