import axios from "axios";
import { server } from "./server";

export function calculatePost(text) {
  const regex = /Total No\. Of Post\s*:\s*(\d+)\s*(?:Post|Posts|Post\/)/i;
  const match = text.match(regex);
  if (match) {
    return `${match[1]} Posts`;
  } else {
    return "Not Mentioned";
  }
}

/**
 * Formats a string of dates into an HTML unordered list.
 * @param {string} arrangeList
 * @returns {string} - The formatted HTML string with <ul> and <li> elements.
 */

export const formatList = (arrangeList) => {
  const items = arrangeList.split("`");
  const listItems = items
    .map((item, index) => `<li key=${index}>${item}</li>`)
    .join("");
  return `<ul>${listItems}</ul>`;
};

export const formatLinks = (arrangeList) => {
  const items = arrangeList.split("~");
  const listItems = items
    .map((item, index) => {
      const parts = item.split("`");
      if (parts.length === 2) {
        const [text, url] = parts;
        return `<li key="${index}">${text} <span class="mx-2">-</span> <a href="${url}" target="_blank" rel="noopener noreferrer">Click Here</a></li>`;
      } else if (parts.length === 1 && item.trim() !== "") {
        return `<li key="${index}">${item}</li>`;
      } else {
        return `<li key="${index}">Invalid format</li>`;
      }
    })
    .join("");
  return `<ul>${listItems}</ul>`;
};

export const toggleSaveJob = (ev_id, setSavedJobs) => {
  try {
    const existingSavedJobs = localStorage.getItem("savedJobs");
    let savedJobsArray = existingSavedJobs ? JSON.parse(existingSavedJobs) : [];
    const index = savedJobsArray.indexOf(ev_id);

    if (index !== -1) {
      savedJobsArray.splice(index, 1);
    } else {
      savedJobsArray.push(ev_id);
    }

    localStorage.setItem("savedJobs", JSON.stringify(savedJobsArray));
    setSavedJobs(savedJobsArray);

    console.log(savedJobsArray);
  } catch (error) {
    console.error("Error toggling job save:", error);
  }
};

export const fetchSavedJobs = (setSavedJobs) => {
  try {
    const savedJobsData = localStorage.getItem("savedJobs");
    if (savedJobsData) {
      setSavedJobs(JSON.parse(savedJobsData));
    }
  } catch (error) {
    console.error("Error fetching saved jobs:", error);
  }
};

export async function fetchVacancies(categoryId) {
  try {
    const response = await axios.get(
      `${server}/data.php?ev_data_id=${categoryId}`
    );
    return response.data.length || 0;
  } catch (error) {
    console.error("Error fetching vacancies:", error);
    return 0;
  }
}
