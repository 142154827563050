import React from "react";

const BannerAd = () => (
  <div>
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-5680003571314768"
      data-ad-slot="1457281501"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
    <script>
      {`
        (adsbygoogle = window.adsbygoogle || []).push({});
      `}
    </script>
  </div>
);

export default BannerAd;
