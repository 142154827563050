import React from "react";

const FeedAd = () => (
  <div>
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="-fb+5w+4e-db+86"
      data-ad-client="ca-pub-5680003571314768"
      data-ad-slot="4694375556"
    ></ins>
    <script>
      {`
        (adsbygoogle = window.adsbygoogle || []).push({});
      `}
    </script>
  </div>
);

export default FeedAd;
