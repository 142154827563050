import axios from "axios";
import { useState, useEffect } from "react";
import { calculatePost, fetchSavedJobs, toggleSaveJob } from "../utils.js";
import { Link, useLocation } from "react-router-dom";
import { server } from "../server.js";
import moment from "moment";
import { Helmet } from "react-helmet";

function Search() {
  const location = useLocation();
  const searchQuery = location.state?.searchQuery || "";

  const [searchTerm, setSearchTerm] = useState(searchQuery);
  const [searchResult, setSearchResult] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchResults = async () => {
      if (searchTerm.trim() === "") {
        setSearchResult([]);
        return;
      }

      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/search.php`, {
          params: { search_text: searchTerm },
        });
        setSearchResult(response.data || []);
      } catch (err) {
        console.error("Error fetching search results:", err);
        setError(
          err.response ? err.response.data : "An unexpected error occurred"
        );
        setSearchResult([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchTerm) {
      fetchResults();
    }
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "") {
      setSearchResult([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${server}/search.php`, {
        params: { search_text: searchTerm },
      });
      setSearchResult(response.data || []);
    } catch (err) {
      console.error("Error fetching search results:", err);
      setError(
        err.response ? err.response.data : "An unexpected error occurred"
      );
      setSearchResult([]);
    } finally {
      setIsLoading(false);
    }
  };

  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    fetchSavedJobs(setSavedJobs);
  }, []);

  const handleToggleSaveJob = (ev_id) => {
    toggleSaveJob(ev_id, setSavedJobs);
  };

  const isJobSaved = (ev_id) => {
    return savedJobs.includes(ev_id);
  };

  return (
    <>
      <Helmet>
        <title>Search : Sarkari Result, Sarkari Naukri</title>
      </Helmet>

      <section className="pt-xl-5 pt-6 glow-bg bg-white">
        <div className="container pb-5">
          <div className="row my-4 pb-3">
            <div className="col-xxl-6 offset-xxl-3 col-md-8 offset-md-2 col-12">
              <div className="text-center mb-3">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-2">
                    <span className="text-dark fs-5">
                      SARKARI RESULT, SARKARI NAUKRI - GOVERNMENT JOBS
                    </span>
                  </div>
                  <h1 className="display-4 mb-0 lh-1">
                    Explore the Newest Job Openings
                    <br />
                    <span className="text-primary">Search</span>
                  </h1>
                  <form
                    onSubmit={handleSubmit}
                    className="form-inline px-xl-8 mx-xl-8 mt-3"
                  >
                    <div className="d-flex align-items-center">
                      <span className="position-absolute ps-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </span>
                      <input
                        type="search"
                        className="form-control ps-6 form-control-lg"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pb-8 bg-white">
        <div class="container mb-4">
          <div class="row">
            <div class="offset-xl-2 col-xl-8 col-md-12 col-12">
              <div className="mt-8">
                <div className="mb-6">
                  <span className="text-uppercase text-primary fw-semibold ls-md">
                    Showing {searchResult.length} Search results
                  </span>
                </div>

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error fetching posts</p>
                ) : (
                  searchResult.map((post) => {
                    const {
                      ev_id,
                      name,
                      tag,
                      title,
                      postupdatedate,
                      vacancy_details,
                    } = post;
                    return (
                      <Link
                        to="/post"
                        state={{ postId: ev_id }}
                        className="card card-bordered mb-3 card-hover cursor-pointer"
                        key={ev_id}
                      >
                        <div className="card-body">
                          <div className="d-md-flex">
                            <div className="w-100 px-lg-3 px-1 mt-3 mt-xl-1">
                              <div className="d-flex justify-content-between mb-5">
                                <div>
                                  <h3 className="mb-1 fs-4">
                                    <span className="text-inherit">{name}</span>
                                    <span className="badge bg-danger-soft ms-2">
                                      Latest
                                    </span>
                                  </h3>

                                  <div>
                                    <span>
                                      from
                                      <span className="text-dark ms-1">
                                        {tag}
                                      </span>
                                      <i className="bi bi-graph-up ms-3 me-2"></i>
                                    </span>
                                    <span className="ms-0">
                                      {calculatePost(vacancy_details)}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <i
                                    onClick={() => handleToggleSaveJob(ev_id)}
                                    className={`bi ${
                                      isJobSaved(ev_id)
                                        ? "bi-bookmark-fill"
                                        : "bi-bookmark"
                                    }`}
                                  ></i>
                                </div>
                              </div>

                              <div className="d-md-flex justify-content-between">
                                <div className="mb-2 mb-md-0">
                                  <span className="me-2">
                                    <span>{title}</span>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    {moment(postupdatedate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Search;
