import { Link, useNavigate } from "react-router-dom";
import { Category, Departments, States } from "./Home";
import { useState } from "react";

function Header() {
  const [input, setInput] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      navigate("/search", { state: { searchQuery: input } });
      setIsCollapsed(false);
    }
  };

  return (
    <>
      <div
        className={`collapse bg-white ${isCollapsed ? "hide" : ""}`}
        id="collapseExample"
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-11 col-10">
              <div class="py-4">
                <form onSubmit={handleSubmit} class="d-flex align-items-center">
                  <span class="position-absolute ps-3">
                    <i class="bi bi-search"></i>
                  </span>
                  <input
                    type="search"
                    value={input}
                    onChange={handleChange}
                    class="form-control ps-6 border-0 py-3 smooth-shadow-sm"
                    placeholder="Search Latest Jobs, Results or Admit Card"
                  />
                </form>
              </div>
            </div>
            <div class="col-md-1 col-2">
              <div>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg sticky-top">
        <div class="container">
          <Link to="/" class="navbar-brand">
            <img
              src="/assets/images/logo.png"
              height="38"
              alt="Sarkari Result, Sarkari Naukri"
            />
          </Link>
          <div class="d-flex align-items-center order-lg-3">
            <div class="d-flex align-items-center">
              <div class="d-none d-md-block">
                <a
                  href="#"
                  class="ms-2 me-md-4"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i class="bi bi-search fs-4"></i>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                  target="_blank"
                  class="btn btn-outline-primary fw-normal ms-1 rounded-pill px-4"
                >
                  Download App
                </a>
                <Link
                  to="/notifications"
                  class="btn bg-orange fw-normal text-white ms-1 rounded-pill px-3"
                >
                  <div>
                    <i className="bi bi-bell"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div>
              <button
                class="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-default"
                aria-controls="navbar-default"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="icon-bar top-bar mt-0"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
              </button>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <ul class="navbar-nav ms-lg-5 ps-lg-5">
              <li class="nav-item">
                <Link class="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/vacancies">
                  Vacancies
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/saved">
                  Saved Jobs
                  <span className="badge bg-danger-soft rounded-pill px-3 ms-2">
                    New
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
