import React from 'react';
import { Helmet } from 'react-helmet';

const AdSense = () => (
  <Helmet>
    <script
      async
      src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`}
    />
    <script>
      {`
        (adsbygoogle = window.adsbygoogle || []).push({
          google_ad_client: "ca-pub-5680003571314768",
          enable_page_level_ads: true
        });
      `}
    </script>
  </Helmet>
);

export default AdSense;
